/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text, Loader, Button, FlexBox } from 'vspry-style-components'

import { useFunctionState } from 'vspry-hooks'
import { useAuth } from 'context/authContext'

import { getCurrentContactTerms, createContracts, Term } from 'api/terms'

import PageContainer from 'components/style-elements/containers/PageContainer'
import TermItem from 'components/terms/TermItem'
import { useLocale } from 'context/localeContext'
import theme from 'styles/theme'

type State = {
    terms: Term[]
    fetching: boolean
    agreedTerms: string[]
    allAgreed: boolean
    submitting: boolean
}
const init: State = {
    terms: [],
    fetching: false,
    agreedTerms: [],
    allAgreed: false,
    submitting: false,
}

export default function TermsAndConditionsPage() {
    const { translate } = useLocale()
    const { user, account, getUser, logout } = useAuth()
    const navigate = useNavigate()
    const [state, setState] = useFunctionState(init)

    const { terms, agreedTerms, allAgreed, submitting } = state

    const getNewTerms = async () => {
        setState({ fetching: true })
        const newTerms = await getCurrentContactTerms()
        if (newTerms) setState({ fetching: false, terms: newTerms })
    }
    useEffect(() => {
        getNewTerms()
    }, [])

    const allAgreedCheck = (newAgreedTerms?: string[]) => {
        if (newAgreedTerms) return terms.every((t) => newAgreedTerms.includes(t.id))
        return terms.every((t) => agreedTerms.includes(t.id))
    }

    const setAgree = (term: Term) => {
        const newAgreedTerms = [...agreedTerms]
        if (newAgreedTerms.find((t) => t === term.id)) {
            return setState({ agreedTerms: newAgreedTerms.filter((t) => t !== term.id), allAgreed: false })
        }
        newAgreedTerms.push(term.id)
        return setState({ agreedTerms: newAgreedTerms, allAgreed: allAgreedCheck(newAgreedTerms) })
    }

    const isSelected = (term: Term) => !!agreedTerms.find((t) => t === term.id)

    const agreeToTerms = async () => {
        if (!account || !user) return
        setState({ submitting: true })
        const contracts: Parameters<typeof createContracts>[0]['contracts'] = []
        const device = navigator.userAgent
        agreedTerms.forEach((t) =>
            contracts.push({
                term: t,
                accountName: account.id,
                signatory: user.id,
                device,
            })
        )
        const res = await createContracts({ contracts })
        if (res?.success) {
            await getUser()
            navigate('/')
        }
    }

    return (
        <PageContainer title={translate('pages.termsAndConditions.title')}>
            {state.fetching ? (
                <Loader height='75vh' />
            ) : (
                <FlexBox $column gap='small'>
                    <Text size='medium'>{translate('pages.termsAndConditions.instructions')}</Text>
                    <div>
                        {terms.map((term) => (
                            <TermItem key={`item-${term.id}`} term={term} onChange={setAgree} checked={isSelected(term)} />
                        ))}
                    </div>
                    <Button id='terms-submit-button' disabled={!allAgreed || submitting} onClick={() => agreeToTerms()}>
                        {submitting ? translate('buttons.submitting') : translate('buttons.submit')}
                    </Button>
                    <Button
                        id='terms-back-button'
                        disabled={submitting}
                        customColor={`${theme.disabledButtonBG}40`}
                        textColor={theme.text}
                        onClick={logout}
                    >
                        {translate('buttons.signOut')}
                    </Button>
                </FlexBox>
            )}
        </PageContainer>
    )
}
