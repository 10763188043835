/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import {
    decodeJWT,
    epochAtSecondsFromNow,
    epochTimeIsPast,
    FALLBACK_EXPIRE_TIME,
    FetchError,
    generateCodeChallenge,
    generateRandomString,
    getRefreshExpiresIn,
    postWithXForm,
} from 'utils/sso'
import { useBrowserStorage } from 'hooks'

const codeVerifierStorageKey = 'PKCE_code_verifier'

export type SSOConfig = {
    clientId: string
    authorizationEndpoint: string
    tokenEndpoint: string
    redirectUri: string
    scope: string
    logoutEndpoint: string
    logoutRedirect: string
    onRefreshTokenExpire: (event: { logIn: () => void; logOut: () => void }) => void
    storage?: 'local' | 'session'
    decodeToken: boolean
    autoLogin: boolean
    clearURL: boolean
    tokenExpiresIn?: number
    refreshTokenExpiresIn?: number
    extraAuthParameters?: Record<string, string>
    extraLogoutParameters?: Record<string, string>
    extraTokenParameters?: Record<string, string>
    postLogin: (redirect: string) => void
}
const redirectToLogin = async (config: SSOConfig) => {
    // Create and store a random string in sessionStorage, used as the 'code_verifier'
    const codeVerifier = generateRandomString(96)
    sessionStorage.setItem(codeVerifierStorageKey, codeVerifier)

    // Hash and Base64URL encode the code_verifier, used as the 'code_challenge'
    const codeChallenge = await generateCodeChallenge(codeVerifier)

    // Set query parameters and redirect user to OAuth2 authentication endpoint
    const params = new URLSearchParams({
        response_type: 'code',
        client_id: config.clientId,
        scope: config.scope,
        redirect_uri: config.redirectUri,
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        ...config.extraAuthParameters,
    })

    return window.location.replace(`${config.authorizationEndpoint}?${params.toString()}`)
}

const redirectToLogout = (config: SSOConfig, token: string, refreshToken: string | undefined, idToken: string | undefined) => {
    console.log(config.logoutRedirect ?? config.redirectUri)
    const params = new URLSearchParams({
        token: refreshToken || token,
        token_type_hint: refreshToken ? 'refresh_token' : 'access_token',
        client_id: config.clientId,
        post_logout_redirect_uri: config.logoutRedirect ?? config.redirectUri,
        ui_locales: window.navigator.languages.reduce((a, b) => `${a} ${b}`),
        ...config.extraLogoutParameters,
    })
    if (idToken) params.append('id_token_hint', idToken)

    window.location.replace(`${config.logoutEndpoint}?${params.toString()}`)
}

const postTokenRequest = async (tokenEndpoint: SSOConfig['tokenEndpoint'], tokenRequest: Record<string, string>) => {
    const response = await postWithXForm(tokenEndpoint, tokenRequest)
    const body = await response.json()
    if (body?.access_token !== undefined) return body
    throw new Error(body)
}

const fetchTokens = async (config: SSOConfig, urlParams: URLSearchParams) => {
    /*
      The browser has been redirected from the authentication endpoint with
      a 'code' url parameter.
      This code will now be exchanged for Access- and Refresh Tokens.
    */
    const authCode = urlParams.get('code')
    const codeVerifier = window.sessionStorage.getItem(codeVerifierStorageKey)

    if (!authCode) throw new Error("Parameter 'code' not found in URL. \nHas authentication taken place?")
    if (!codeVerifier) throw new Error("Can't get tokens without the CodeVerifier. \nHas authentication taken place?")

    const tokenRequest = {
        grant_type: 'authorization_code',
        code: authCode,
        scope: config.scope,
        client_id: config.clientId,
        redirect_uri: config.redirectUri,
        code_verifier: codeVerifier,
        ...config.extraTokenParameters,
    }
    return postTokenRequest(config.tokenEndpoint, tokenRequest)
}

const fetchWithRefreshToken = async ({ config, refreshToken }: { config: SSOConfig; refreshToken: string }) => {
    const refreshRequest = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: config.scope,
        client_id: config.clientId,
        redirect_uri: config.redirectUri,
        ...config.extraTokenParameters,
    }
    return postTokenRequest(config.tokenEndpoint, refreshRequest)
}

const useSSOProvider = (config: SSOConfig) => {
    const [refreshToken, setRefreshToken] = useBrowserStorage<string | undefined>('SSO_refreshToken', undefined, config.storage)
    const [refreshTokenExpire, setRefreshTokenExpire] = useBrowserStorage(
        'SSO_refreshTokenExpire',
        epochAtSecondsFromNow(2 * FALLBACK_EXPIRE_TIME),
        config.storage
    )
    const [token, setToken] = useBrowserStorage('SSO_token', '', config.storage)
    const [tokenExpire, setTokenExpire] = useBrowserStorage('SSO_tokenExpire', epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME), config.storage)
    const [idToken, setIdToken] = useBrowserStorage<string | undefined>('SSO_idToken', undefined, config.storage)
    const [loginInProgress, setLoginInProgress] = useBrowserStorage('SSO_loginInProgress', false, config.storage)
    const [loginRedirect, setLoginRedirect] = useBrowserStorage('SSO_loginRedirect', '/home', config.storage)
    const [refreshInProgress, setRefreshInProgress] = useBrowserStorage('SSO_refreshInProgress', false, config.storage)
    const [tokenData, setTokenData] = useState()
    const [idTokenData, setIdTokenData] = useState()
    const [error, setError] = useState<string | null>(null)

    const clearStorage = () => {
        setRefreshToken(undefined)
        setToken('')
        setTokenExpire(epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME))
        setRefreshTokenExpire(epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME))
        setIdToken(undefined)
        setTokenData(undefined)
        setIdTokenData(undefined)
        setLoginInProgress(false)
    }

    const handleTokenResponse = (response: { access_token: string; refresh_token: string; id_token: string; expires_in?: number }) => {
        setToken(response.access_token)
        setRefreshToken(response.refresh_token)
        setIdToken(response.id_token)
        try {
            if (response.id_token) {
                const data = decodeJWT(response.id_token)
                setIdTokenData(data)
                setTokenExpire(data.exp)
            }
        } catch (e) {
            if (e instanceof Error) console.warn(`Failed to decode idToken: ${e.message}`)
        }
        const tokenExpiresIn = config.tokenExpiresIn ?? response.expires_in ?? FALLBACK_EXPIRE_TIME
        // setTokenExpire(epochAtSecondsFromNow(tokenExpiresIn))
        const refreshTokenExpiresIn = config.refreshTokenExpiresIn ?? getRefreshExpiresIn(tokenExpiresIn, response)
        setRefreshTokenExpire(epochAtSecondsFromNow(refreshTokenExpiresIn))
        try {
            if (config.decodeToken) setTokenData(decodeJWT(response.access_token))
        } catch (e) {
            if (e instanceof Error) console.warn(`Failed to decode access token: ${e.message}`)
        }
    }

    const logOut = () => {
        clearStorage()
        setError(null)
        if (config?.logoutEndpoint) redirectToLogout(config, token, refreshToken, idToken)
    }

    const logIn = async (redirect = '/home') => {
        clearStorage()
        setLoginInProgress(true)
        setLoginRedirect(redirect)
        redirectToLogin(config).catch((e) => {
            console.error(e)
            setError(e.message)
            setLoginInProgress(false)
        })
    }

    const handleExpiredRefreshToken = async (initial = false) => {
        // If it's the first page load, OR there is no sessionExpire callback, we trigger a new login
        if (initial) return clearStorage()
        if (!config.onRefreshTokenExpire) return logIn()
        return config.onRefreshTokenExpire({ logIn, logOut })
    }

    const refreshAccessToken = async (initial = false) => {
        if (!token) return null
        // The token has not expired. Do nothing
        if (!epochTimeIsPast(tokenExpire)) return null

        // Other instance (tab) is currently refreshing. This instance skip the refresh if not initial
        if (refreshInProgress && !initial) return null

        // The refreshToken has expired
        if (epochTimeIsPast(refreshTokenExpire)) return handleExpiredRefreshToken(initial)

        // The access_token has expired, and we have a non-expired refresh_token. Use it to refresh access_token.
        if (refreshToken) {
            setRefreshInProgress(true)
            fetchWithRefreshToken({ config, refreshToken })
                .then((result) => handleTokenResponse(result))
                // eslint-disable-next-line consistent-return
                .catch((e) => {
                    if (e instanceof FetchError) {
                        // If the fetch failed with status 400, assume expired refresh token
                        if (e.status === 400) {
                            return handleExpiredRefreshToken(initial)
                        }
                        // Unknown error. Set error, and login if first page load

                        console.error(e)
                        setError(e?.message ?? 'Unexpected error')
                        if (initial) logIn()
                    }
                    // Unknown error. Set error, and login if first page load
                    else if (e instanceof Error) {
                        console.error(e)
                        setError(e.message)
                        if (initial) logIn()
                    }
                })
            setRefreshInProgress(false)
            return null
        }
        return handleExpiredRefreshToken(initial)
    }

    // This ref is used to make sure the 'fetchTokens' call is only made once.
    // Multiple calls with the same code will, and should, return an error from the API
    // See: https://beta.reactjs.org/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    const didFetchTokens = useRef(false)

    // The client has been redirected back from the auth endpoint with an auth code
    const authCallback = async (urlParams: URLSearchParams) => {
        if (loginInProgress) {
            if (!urlParams.get('code')) {
                // This should not happen. There should be a 'code' parameter in the url by now..."
                const errorDescription = urlParams.get('error_description') || 'Bad authorization state. Refreshing the page might solve the issue.'
                console.error(errorDescription)
                setError(errorDescription)
                logOut()
                return
            }
            // Make sure we only try to use the auth code once
            if (!didFetchTokens.current) {
                didFetchTokens.current = true
                // Request tokens from auth server with the auth code
                fetchTokens(config, urlParams)
                    .then((tokens) => {
                        handleTokenResponse(tokens)
                        // Call any postLogin function in authConfig
                        if (config?.postLogin) config.postLogin(loginRedirect)
                    })
                    .catch((e) => {
                        console.error(e)
                        setError(e.message)
                    })
                    .finally(() => {
                        if (config.clearURL) {
                            // Clear ugly url params
                            window.history.replaceState(null, '', window.location.pathname)
                        }
                        setLoginInProgress(false)
                    })
            }
        }
    }

    // useEffect(() => {
    //     if (idToken) {
    //         setAuthProvider(new SSOAuthProvider(logOut) as unknown as AuthInterface)
    //         window.auth.setIDToken(idToken)
    //     }
    // }, [idToken])

    useEffect(() => {
        const timeout = setTimeout(refreshAccessToken, tokenExpire * 1000 - Date.now() - 10000)
        return () => clearTimeout(timeout)
    }, [tokenExpire])

    useEffect(() => {
        // First page visit
        if (!token && config.autoLogin) {
            logIn()
            return
        }

        refreshAccessToken(true)

        // Page refresh after login has succeeded
        try {
            if (idToken) setIdTokenData(decodeJWT(idToken))
        } catch (e) {
            if (e instanceof Error) console.warn(`Failed to decode idToken: ${e.message}`)
        }
        try {
            if (config.decodeToken) setTokenData(decodeJWT(token))
        } catch (e) {
            if (e instanceof Error) console.warn(`Failed to decode access token: ${e.message}`)
        }
    }, [])

    return { authCallback, logIn, logOut, token, tokenData, idToken, idTokenData, error, loginInProgress }
}

type SSOContext = ReturnType<typeof useSSOProvider>
const context = createContext({} as SSOContext)
const { Provider } = context

export function SSOProvider({ config, children }: { config: SSOConfig; children: ReactNode }) {
    const sso = useSSOProvider(config)
    return <Provider value={sso}>{children}</Provider>
}

export const useSSO = () => useContext(context)
