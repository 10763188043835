/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlexBox, Text } from 'vspry-style-components'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import theme from 'styles/theme'
import Logo from 'components/Logo'
import LanguageSelector from 'components/LanguageSelector'
import auth from 'services/auth'
import { useAppConfig } from 'context/appConfigContext'

const Inner = styled(FlexBox)<{ size?: keyof NonNullable<typeof screens> }>`
    max-width: ${(props) => (props.size ? screens?.[props.size] : screens?.small)}px;
`

const Header = styled(FlexBox)`
    position: fixed;
    height: 82px;
    top: 0;
    background-color: ${(props) => props.theme.primaryBG};
`

const Core = styled(FlexBox)`
    position: relative;
    margin-top: 82px;
    color: ${(props) => props.theme.text};
    background-color: ${(props) => props.theme.secondaryBG};
    min-height: calc(100vh - 82px);
    text-align: center;
    border-radius: 20px 20px 0px 0px;
`

type PageContainerProps = {
    children: ReactNode
    // eslint-disable-next-line react/no-unused-prop-types
    title: string
    size?: keyof NonNullable<typeof screens>
}
export default function PageContainer({ children, size }: PageContainerProps) {
    const navigate = useNavigate()
    const { appConfig } = useAppConfig()

    useEffect(() => {
        auth.getUser().then((d) => !d && navigate('/'))
    }, [])

    return (
        <>
            <Header
                direction='column'
                justify='center'
                align={theme.logoHeaderLocation === 'center' ? 'center' : 'flex-start'}
                padding='medium'
                width='100%'
            >
                <Logo size='125px' onClick={() => navigate('/')} />
            </Header>
            <Core direction='column' align='center' justify='space-between' width='100%'>
                <Inner justify='flex-start' padding='large' direction='column' width='100%' grow='1' size={size}>
                    {children}
                </Inner>
                <div>
                    <LanguageSelector />
                    <Text size='xSmall' margin='no' $light>
                        {' '}
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <a href={appConfig.footer_link_url || 'https://app-change-log.vspry.com/'} target='_blank' rel='noreferrer'>
                            {window.configuration['APP_VERSION']}
                        </a>{' '}
                        {/* eslint-disable-next-line i18next/no-literal-string */}©{' '}
                        {window.configuration['APP_FOOTER'] || 'VSPRY INTERNATIONAL LIMITED 2019-2023'}
                    </Text>
                </div>
            </Core>
        </>
    )
}

PageContainer.propTypes = {
    children: PropTypes.node.isRequired,
}
