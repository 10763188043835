/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { FlexBox, PopIcon, Text, ConfirmSwal } from 'vspry-style-components'
import { VspryMenu } from 'vspry-components'

import { useClaims } from 'context/claimsContext'
import { useMessaging } from 'context/messageContext'
import { useLocale } from 'context/localeContext'
import { useAuth } from 'context/authContext'
import { useAppConfig } from 'context/appConfigContext'

import {
    hasMerchantRewards,
    hasSubscriptionFeature,
    isConsumer,
    isConsumerMember,
    isMerchantOwner,
    isMerchantSenior,
    isMerchantStaff,
} from 'utils/config'
import { createActivity } from 'api/common'
import theme from 'styles/theme'
import { useRedemptions } from 'context/redemptionsContext'
import { isAndroid } from 'react-device-detect'

const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches
    if (document.referrer.startsWith('android-app://')) {
        return `twa`
    }
    if (navigator.standalone || isStandalone) {
        return `standalone`
    }
    return `browser`
}

const MenuIconContainer = styled.div`
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9998;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: ${(props) => props.theme.buttonBG};
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        cursor: pointer;
        background: ${(props) => props.theme.buttonBG}c8;
    }
`

function NavDrawer() {
    const [menuOpen, setOpen] = useState(false)
    const { translate } = useLocale()
    const { account, currentProduct, user, setCurrentAccount } = useAuth()
    const { appConfig } = useAppConfig()
    const { unread } = useMessaging()
    const { unread: unreadClaims } = useClaims()
    const { unread: unreadRedemptions } = useRedemptions()
    const navigate = useNavigate()
    const location = useLocation()

    const signOut = async () => {
        setOpen(false)
        return Swal.fire({
            text: translate('swal.signOutConfirm'),
            icon: 'warning',
            confirmButtonText: translate('buttons.signOut'),
            confirmButtonColor: theme.buttonBG,
            cancelButtonColor: theme.disabledButtonBG,
            showConfirmButton: true,
            showCancelButton: true,
        }).then((v) => v.isConfirmed && navigate('/signout'))
    }

    const navItems = [
        { title: `navigation.productHome`, icon: 'home', to: '/home', condition: () => true },
        {
            title: `navigation.chargeCustomer`,
            icon: 'money bill alternate',
            to: '/createbill',
            condition: (config, p, a) => isMerchantStaff(a) && p?.asset.includes('Merchant'),
        },
        {
            title: `navigation.rewardConfig`,
            icon: 'cog',
            to: '/rewards/setup',
            condition: (config, p, a) => isMerchantSenior(a) && config?.assets_enabled.includes('Reward'),
        },
        {
            title: `navigation.healthReferral`,
            icon: 'stethoscope',
            to: '/healthreferral',
            condition: (config, p, a) => config?.health_cover_referral_enabled && isMerchantStaff(a),
        },
        {
            title: `navigation.getHealthCover`,
            icon: 'stethoscope',
            to: '/healthreferral',
            condition: (config, p, a) => config?.health_cover_referral_enabled && isConsumerMember(a),
        },
        {
            title: `navigation.referFriend`,
            icon: 'share alternate',
            onClick: (c, p) =>
                navigator
                    .share({
                        url: `/#/referral?product=${p?.productId}&referrer=${p?.id}`,
                        text: c.consumer_refer_text.replace(/{{product}}/g, p?.product),
                        title: c.consumer_refer_title,
                    })
                    .catch((e) => console.warn(e)),
            condition: (c, p, a) => c.consumer_refer_title && c.consumer_refer_text && navigator.canShare && !!p && isConsumer(a),
        },
        {
            title: `navigation.referFriend`,
            icon: 'share alternate',
            onClick: (c) =>
                navigator
                    .share({
                        url: `/#/referral?referrer=${user.id}`,
                        text: c.merchant_refer_text,
                        title: c.merchant_refer_title,
                    })
                    .catch((e) => console.warn(e)),
            condition: (c, p, a) => c.merchant_refer_title && c.merchant_refer_text && navigator.canShare && isMerchantStaff(a),
        },
        { title: `navigation.inbox`, icon: 'inbox', to: '/inbox', condition: (c, p, a) => !!a, count: unread },
        {
            title: `navigation.rewardClaims`,
            icon: 'star',
            to: '/rewards/claim',
            condition: (config, product, a) => isConsumer(a) && product?.asset === 'Reward',
        },
        {
            title: `navigation.claims`,
            icon: 'star',
            to: '/rewards/claims',
            condition: (config, p, a) =>
                isMerchantStaff(a) &&
                config?.assets_enabled.includes('Reward') &&
                hasMerchantRewards(account) &&
                (!config?.merchant_subscriptions_enabled || hasSubscriptionFeature('createrewards', a)),
            count: Object.values(unreadClaims).reduce((t, c) => t + c, 0),
        },
        {
            title: `navigation.redemptions`,
            icon: 'thumbtack',
            to: '/rewards/redemptions',
            condition: (config, p, a) =>
                isMerchantStaff(a) &&
                config?.assets_enabled.includes('Reward') &&
                hasMerchantRewards(account) &&
                (!config?.merchant_subscriptions_enabled || hasSubscriptionFeature('createrewards', a)),
            count: Object.values(unreadRedemptions).reduce((t, c) => t + c, 0),
        },
        {
            title: `navigation.checkins`,
            icon: 'compass',
            to: '/checkins',
            condition: (config, p, a) =>
                isMerchantStaff(a) &&
                config?.assets_enabled.includes('Reward') &&
                hasMerchantRewards(account) &&
                (!config?.merchant_subscriptions_enabled || hasSubscriptionFeature('checkin', a)),
        },
        {
            title: `navigation.customers`,
            icon: 'address book',
            to: '/rewards/customers',
            condition: (config, p, a) =>
                isMerchantStaff(a) &&
                config?.assets_enabled.includes('Reward') &&
                hasMerchantRewards(account) &&
                (!config?.merchant_subscriptions_enabled || hasSubscriptionFeature('createrewards', a)),
        },
        {
            title: `navigation.marketing`,
            icon: 'target',
            to: '/marketing',
            condition: (config, p, a) => config?.marketing_enabled && isMerchantStaff(a) && p,
        },
        {
            title: `navigation.activations.merchant`,
            icon: 'play circle outline',
            to: '/marketing/activations',
            condition: (config, p, a) => config?.marketing_enabled && isMerchantStaff(a) && p,
        },
        {
            title: `navigation.activations.consumer`,
            icon: 'play circle outline',
            to: '/activations',
            condition: (config, p, a) => config?.marketing_enabled && isConsumer(a),
        },
        {
            title: `navigation.call`,
            icon: 'phone',
            onClick: (c, p, a) => {
                createActivity({
                    accountID: a.id,
                    holdingID: p.id,
                    to: `tel:${p.bookingPhone}`,
                    from: window.location.href,
                    type: 'User call merchant',
                })
                window.open(`tel:${p.bookingPhone}`)
            },
            condition: (config, product, a) => isConsumer(a) && product?.bookingPhone,
        },
        {
            title: `navigation.book`,
            icon: 'calendar alternate outline',
            onClick: (c, p, a) =>
                ConfirmSwal.fire({
                    title: translate('swal.book.title'),
                    text: translate('swal.book.text'),
                    iconColor: theme.text,
                    confirmButtonColor: theme.primaryOBJ,
                }).then((r) => {
                    if (r?.value) {
                        createActivity({
                            accountID: a.id,
                            holdingID: p.id,
                            to: p.bookingURL,
                            from: window.location.href,
                            type: 'User book merchant',
                        })
                        window.open(p.bookingURL, '_blank')
                    }
                }),
            condition: (config, product, a) => isConsumer(a) && product?.bookingURL,
        },
        { title: `navigation.doctorSearch`, icon: 'doctor', to: '/policy/doctors', condition: (config, product) => product?.asset === 'Policy' },
        {
            title: `navigation.providerSearch`,
            icon: 'hospital',
            to: '/policy/providers',
            condition: (config, product) => product?.asset === 'Policy',
        },
        ...(appConfig
            ? appConfig.external_navigations.map((n) => ({
                  title: n.label,
                  to: n.url,
                  icon: n.iconName,
                  condition: (c, p, a) =>
                      (n.audience === 'Consumers' && isConsumer(a)) || (n.audience === 'Merchants' && isMerchantStaff(a)) || n.audience === 'Both',
              }))
            : []),
        {
            title: `navigation.documents`,
            icon: 'archive',
            to: '/documents',
            condition: (config, p, a) => config?.document_store_enabled && isConsumerMember(a),
        },
        {
            title: `navigation.companyRecords`,
            icon: 'archive',
            items: [
                {
                    title: `navigation.companyRecords.bankAccounts`,
                    icon: 'briefcase',
                    to: '/documents/bankaccounts',
                    condition: (c, p, a) => c?.business_loans_enabled && isMerchantSenior(a),
                },
                {
                    title: `navigation.companyRecords.financialStatements`,
                    icon: 'chart bar',
                    to: '/documents/financialstatements',
                    condition: (c, p, a) => c?.business_loans_enabled && isMerchantSenior(a),
                },
                {
                    title: `navigation.companyRecords.registrationDocuments`,
                    icon: 'address card',
                    to: '/documents/registrationdocuments',
                    condition: (c, p, a) => c?.business_loans_enabled && isMerchantSenior(a),
                },
            ],
            section: `top`,
            collapsible: true,
            reverse: false,
        },
        { title: `navigation.viewOrders`, icon: 'cart', to: '/orders', condition: (c, product) => product?.asset === 'Case' },
        { title: `navigation.viewScans`, icon: 'cart', to: '/marketplace/scans', condition: (c, product) => product?.asset === 'Case' },
        ...(appConfig.direct_debit_enabled || appConfig.reimbursement_enabled || appConfig.loan_payout_enabled
            ? [
                  {
                      title: `navigation.payments`,
                      icon: 'payment',
                      items: [
                          {
                              title: `navigation.directCredit`,
                              to: '/directcredit',
                              icon: 'calendar plus',
                              condition: (config, product) =>
                                  (product?.asset === 'Loan' && product?.balance !== 0) || product?.asset === 'Saving' || product?.asset === 'Laybuy',
                          },
                          {
                              title: `navigation.directDebit`,
                              to: '/directdebit',
                              icon: 'calendar alternate',
                              condition: (config, product) =>
                                  ((product?.asset === 'Loan' && product?.balance !== 0) ||
                                      product?.asset === 'Saving' ||
                                      product?.asset === 'Laybuy') &&
                                  config?.direct_debit_enabled,
                          },
                          {
                              title: `navigation.directDebits`,
                              to: '/directdebit/history',
                              icon: 'history',
                              condition: (config, product) =>
                                  ((product?.asset === 'Loan' && product?.balance !== 0) ||
                                      product?.asset === 'Saving' ||
                                      product?.asset === 'Laybuy') &&
                                  config?.direct_debit_enabled,
                          },
                          {
                              title: `navigation.reimbursementapplication`,
                              icon: 'reply',
                              to: '/reimbursementapplication',
                              condition: (config, product) =>
                                  (product?.asset === 'Saving' || product?.asset === 'Policy') && config?.reimbursement_enabled,
                          },
                          {
                              title: `navigation.payoutApplication`,
                              icon: 'share',
                              to: '/payoutapplication',
                              condition: (config, product) => product?.asset === 'Loan' && product?.balance !== 0 && config?.loan_payout_enabled,
                          },
                      ],
                      section: `top`,
                      collapsible: true,
                      reverse: false,
                  },
              ]
            : [
                  {
                      title: `navigation.addFunds`,
                      to: '/directcredit',
                      icon: 'payment',
                      condition: (config, product) =>
                          (product?.asset === 'Loan' && product?.balance !== 0) || product?.asset === 'Saving' || product?.asset === 'Laybuy',
                  },
              ]),
        {
            title: `navigation.addProduct`,
            icon: 'add circle',
            items: [
                {
                    title: `navigation.loanApplication`,
                    icon: 'add',
                    to: '/loans/apply',
                    condition: (config, p, a) => config?.assets_enabled.includes('Loan') && isConsumerMember(a),
                },
                {
                    title: `navigation.linkPolicy`,
                    icon: 'heart',
                    to: '/policy/link',
                    condition: (config, p, a) => config?.assets_enabled.includes('Policy') && isConsumerMember(a),
                },
                {
                    title: `navigation.hsa`,
                    icon: 'dollar sign',
                    to: '/referral?asset=Saving',
                    condition: (config, p, a) => config?.assets_enabled.includes('Saving') && config?.addProduct_enabled && isConsumerMember(a),
                },
                {
                    title: `navigation.hsa`,
                    icon: 'dollar sign',
                    to: '/referral?asset=Merchant',
                    condition: (config, p, a) => config?.assets_enabled.includes('Merchant') && config?.addProduct_enabled && isMerchantOwner(a),
                },
                {
                    title: `navigation.addOtherProduct`,
                    icon: 'ellipsis horizontal',
                    to: '/addproduct',
                    condition: (config, p, a) => config?.addProduct_enabled && (isConsumerMember(a) || isMerchantOwner(a)),
                },
            ],
            section: `top`,
            collapsible: true,
            reverse: false,
        },
        {
            title: `navigation.account`,
            icon: 'user circle',
            items: [
                { title: `navigation.signOut`, icon: 'sign-out', condition: (c, p, a) => !!a, onClick: () => signOut() },
                ...(user?.accounts?.length > 1
                    ? [
                          {
                              title: `navigation.accounts`,
                              icon: 'users',
                              items: user.accounts.map((a) => ({
                                  title: a.accountName,
                                  icon: 'user',
                                  condition: () => true,
                                  onClick: () => setCurrentAccount(a),
                                  selected: account.id === a.id,
                              })),
                              collapsible: true,
                          },
                      ]
                    : []),
                { title: `navigation.help`, icon: 'help circle', to: '/help', condition: () => true },
                {
                    title: `navigation.howToVideos`,
                    icon: 'play circle',
                    to: '/howto',
                    condition: (c, p, a) =>
                        (isMerchantStaff(a) && c.how_to_videos.some((v) => v.audience === 'Merchant')) ||
                        (isConsumer(a) && c.how_to_videos.some((v) => v.audience === 'Consumer')) ||
                        c.how_to_videos.some((v) => v.audience === 'All'),
                },
                {
                    title:
                        getPWADisplayMode() === 'browser' && isAndroid && window.configuration['PLAY_STORE_APP_ID']
                            ? `navigation.downloadApp`
                            : `navigation.addToHome`,
                    icon: 'download',
                    onClick: () => document.dispatchEvent(new Event('refireinstallprompt', { bubbles: true })),
                    condition: () => getPWADisplayMode() === 'browser',
                },
                { title: `navigation.contactUs`, icon: 'call', to: '/contact', condition: (config) => config?.contact_us_menu_enabled },
                { title: `navigation.profile`, icon: 'cogs', to: '/profile', condition: () => true },
                {
                    title: `navigation.plan`,
                    icon: 'clipboard list',
                    to: 'plans',
                    condition: (config, p, a) => config?.merchant_subscriptions_enabled && isMerchantSenior(a),
                },
            ],
            section: `bottom`,
            collapsible: true,
        },
    ]

    const handleNavClick = async (to, onClick) => {
        setOpen(false)
        return setTimeout(() => {
            if (to) {
                if (to.startsWith('http')) window.open(to, '_blank')
                else navigate(to)
            } else if (onClick) {
                onClick(appConfig, currentProduct, account)
            }
        }, 400)
    }

    const renderItem = ({ id, title, to, condition, onClick, ...rest }) =>
        condition(appConfig, currentProduct, account) && (
            <VspryMenu.Item
                id={id ?? title}
                key={title}
                onClick={() => handleNavClick(to ?? undefined, onClick ?? undefined)}
                selected={location.pathname === to}
                {...rest}
            >
                {translate(title) || title}
            </VspryMenu.Item>
        )

    const renderSection = ({ title, items, ...rest }) =>
        items.some((i) =>
            i.items ? i.items.some((k) => k.condition(appConfig, currentProduct, account)) : i.condition(appConfig, currentProduct, account)
        ) && (
            <VspryMenu.Section key={title} label={translate(title) || title} menuOpen={menuOpen} {...rest}>
                <FlexBox $column gap='xSmall'>
                    {items.map((i) => (i.items ? renderSection(i) : renderItem(i)))}
                </FlexBox>
            </VspryMenu.Section>
        )

    return (
        <>
            <VspryMenu open={menuOpen} onClose={() => setOpen(false)} title={<Text size='large'>{translate('pages.home.navigation')}</Text>}>
                <FlexBox $column $grow>
                    <FlexBox $column gap='xSmall'>
                        {navItems.filter((e) => e.section === 'top' || !e.section).map((e) => (e.items ? renderSection(e) : renderItem(e)))}
                    </FlexBox>
                    <FlexBox $column gap='xSmall'>
                        {navItems.filter((e) => e.section === 'bottom').map((e) => (e.items ? renderSection(e) : renderItem(e)))}
                    </FlexBox>
                </FlexBox>
            </VspryMenu>
            <MenuIconContainer onClick={() => setOpen(true)}>
                <PopIcon
                    color='buttonText'
                    name='bars'
                    count={
                        unread + Object.values(unreadClaims).reduce((t, c) => t + c, 0) + Object.values(unreadRedemptions).reduce((t, c) => t + c, 0)
                    }
                    popFontColor='primaryOBJText'
                    popColor={theme.buttonBG === theme.primaryOBJ ? theme.error : theme.primaryOBJ}
                    size='big'
                    fitted
                    id='navbar-icon'
                />
            </MenuIconContainer>
        </>
    )
}

export default NavDrawer
