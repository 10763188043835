/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import theme from '../styles/theme'

const Img = styled.img`
    width: ${(props) => (props.size ? props.size : `200px`)};
`
export default function Logo({ size, onClick }) {
    return <Img src={theme.logoPath} size={size} onClick={onClick} alt='' />
}

Logo.propTypes = {
    size: PropTypes.string,
    onClick: PropTypes.func,
}

Logo.defaultProps = {
    size: null,
    onClick: null,
}
