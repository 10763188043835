/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'semantic-ui-react'
import styled from 'styled-components'
import theme from 'styles/theme'

const StyledDivider = styled(Divider)`
    &.ui.horizontal.divider {
        color: ${(props) => (props.light ? theme.textLight : theme.text)};
        text-transform: none;
        font-weight: 700;
    }

    ${(props) =>
        props.onClick &&
        `:hover {
        cursor: pointer;
    }`}
`

export default function VspryDivider(props) {
    const { children, ...rest } = props
    return (
        <StyledDivider horizontal {...rest}>
            {children}
        </StyledDivider>
    )
}

VspryDivider.propTypes = {
    children: PropTypes.string.isRequired,
}
