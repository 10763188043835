/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import './init'
import 'trix/dist/trix.esm'

import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
// import * as SentryBrowser from '@sentry/browser'

import App from './App'

console.info(`App version ${window.configuration['APP_VERSION']}`)
if (window.configuration['SENTRY_DSN']) {
    try {
        Sentry.init({
            dsn: window.configuration['SENTRY_DSN'],
            integrations: [
                new Sentry.BrowserTracing(),
                new Sentry.Replay({
                    networkDetailAllowUrls: [window.location.origin],
                    maskAllText: false,
                    blockAllMedia: false,
                    maskAllInputs: false,
                    slowClickIgnoreSelectors: [
                        'BLAF-DRP-upload-general-statement-button',
                        'BLAF-DRP-upload-business-permit-button',
                        'BLAF-DRP-upload-financial-statement-button',
                        'BLAF-DRP-upload-tax-return-button',
                        'UBSP-upload-statement-button',
                        'VIP-upload-button-ID',
                        'FLSI-view-statement-button',
                        'BDL-document-view-button',
                    ],
                }),
                // SentryBrowser.captureConsoleIntegration({ levels: ['error'] }),
                // SentryBrowser.httpClientIntegration(),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            networkDetailAllowUrls: [window.location.origin],
            mutationLimit: 100000,
            mutationBreadcrumbLimit: 7500,
            environment: window.configuration['ENVIRONMENT'] || 'production',
        })
    } catch (e) {
        console.error(e)
    }
}

ReactDOM.render(<App />, document.getElementById('root'))
