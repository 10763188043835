/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import PropTypes from 'prop-types'
import styled from 'styled-components'

const Tile = styled.div`
    margin: 0px auto 8px auto;
    max-width: 100%;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 7px 12px 0px rgba(180, 180, 190, 0.3);

    ${(props) => props.thin && `width: 420px;`}
    ${(props) => props.leftAlign && `text-align: left;`}
    ${(props) => props.color && `background-color: ${props.color};`}
    ${(props) => props.borderColor && `border: 2px solid ${props.borderColor};`}
    ${(props) => props.noshadow && `box-shadow: none;`}

    &:hover {
        ${(props) => props.onClick && `cursor:  pointer;`}
    }
`

const Inner = styled.div`
    position: relative;
    padding: 10px;
    ${(props) => props.padded && `padding: 30;`}
    ${(props) => props.customPadding && `padding: ${props.customPadding};`}
`

export default function VspryTile({ children, className, onClick, ...rest }) {
    return (
        <Tile className={className} role='button' onClick={onClick} tabIndex={0} {...rest}>
            <Inner>{children}</Inner>
        </Tile>
    )
}

VspryTile.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
}

VspryTile.defaultProps = {
    children: [],
    onClick: null,
    className: '',
}
